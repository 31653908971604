<!--Header Section-->
<header id="page-topbar">
  <div class="navbar-header" style="padding-left: 40px; padding-right: 40px">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a routerLink="/" class="">
          <span style="color: black; font-size: 24px; font-weight: 600">SHOPLIFE</span>
        </a>
      </div>
    </div>

    <div>
      <!-- LOGO -->
      <div class="navbar-brand-box" style="display: flex; align-items: center; gap: 80px; width: 100%">
        <input type="text" placeholder="Search Category" />
      </div>
    </div>

    <div style="margin-right: 3rem" class="d-none md-d-block">
      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn" style="background-color: #0c8140; color: #fff; border-radius: 40px">
          Get Started for free
        </button>
      </div>
      <div class="dropdown d-inline-block"></div>
      <div class="dropdown d-inline-block"></div>
    </div>
  </div>
</header>

<!--Hero section-->
<!-- <section class="container-fluid hero-section">
  <div class="row">
    <div class="col">
      <div class="hero-content">
        <h1 style="color: #fff">
          Your Trustworthy Transaction Partner in Nigeria
        </h1>
        <p class="text">
          Elevate your shopping experience with our seamless bulk ordering
          process that caters to your business needs and personal preferences.
        </p>
        <button type="button" class="btn" style="background-color: #0c8140; color: #fff; border-radius: 40px">
          Get Started for free
        </button>
      </div>
    </div>
  </div>
</section> -->


<section class="container-fluid hero-section">
  <div id="heroCarousel" class="carousel slide" data-bs-ride="carousel" data-bs-ride="carousel" data-bs-interval="5000">
    <div class="carousel-inner">
      <div class="carousel-item active">
        <div class="row">
          <div class="col">
            <div class="hero-content">
              <h1 style="color: #fff">
                Your Trustworthy Transaction Partner in Nigeria
              </h1>
              <p class="text">
                Elevate your shopping experience with our seamless bulk ordering
                process that caters to your business needs and personal preferences.
              </p>
              <button type="button" class="btn" style="background-color: #0c8140; color: #fff; border-radius: 40px">
                Get Started for free
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="row">
          <div class="col">
            <div class="hero-content">
              <h1 style="color: #fff">
                Another Trustworthy Partner
              </h1>
              <p class="text">
                Experience the best services tailored to your needs.
              </p>
              <button type="button" class="btn" style="background-color: #0c8140; color: #fff; border-radius: 40px">
                Learn More
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>






<!--Product Section-->
<!-- <section class="product-section">
  <h3 class="title">
    Welcome to ShopLife – Where <span style="color: #0c8140">Trust</span> Meets
    <span style="color: #0c8140">Convenience</span>
  </h3>
  <div class="container">
    <div class="row">
      <div class="col-xl-4 col-sm-12 g-8" *ngFor="let product of products">
        <div class="card">
          <a [routerLink]="['/app/ecommerce/product-detail', product.id]" href="javascript: void(0);">
            <img src="{{ product.images[0] }}" alt="" class="img-fluid mx-auto d-block" />
          </a>
          <div class="card-body">
            <div class="mt-4 text-left">
              <div style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                ">
                <p class="text-truncate" style="margin: 0px; font-size: 20px; font-weight: 400">
                  <a [routerLink]="['/app/ecommerce/product-detail', product.id]" href="javascript: void(0);"
                    class="text-dark">{{ product.name }}
                  </a>
                </p>

                <span>
                  <i class="bx bx-heart" style="font-size: 26px"></i>
                </span>
              </div>

              <div style="margin-top: 5px">
                <p style="margin-bottom: 5px">Location: Abuja</p>
                <p>Minimum Order: 5 tonnes</p>

                <p class="text-muted">
                  <i class="bx bx-star text-warning" style="font-size: 20px"></i>
                  <i class="bx bx-star text-warning" style="font-size: 20px"></i>
                  <i class="bx bx-star text-warning" style="font-size: 20px"></i>
                  <i class="bx bx-star text-warning" style="font-size: 20px"></i>
                  <i class="bx bx-star text-warning" style="font-size: 20px"></i>
                </p>
                <p style="color: #0c8140; font-size: 16px; font-weight: 500">
                  {{ formatCurrency(product.price) }}/KG
                </p>
              </div>

              <div class="text-right" style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                ">
                <div class="col-6"></div>
                <div class="col-6" style="
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                  ">
                  <span style="
                      background-color: #1fb0a826;
                      /* padding-right: 11%; */
                      width: 100%;
                      margin-right: -15%;
                      border-bottom-left-radius: 20px;
                      border-top-left-radius: 20px;
                    ">
                    <p style="
                        margin: 0%;
                        padding: 5% 15%;
                        font-weight: 600;
                        color: #0c8140;
                      ">
                      Add to cart
                    </p>
                  </span>
                  <span style="
                      margin-left: 10px;
                      padding: 5px;
                      background-color: #0c8140;
                      border-radius: 50%;
                      display: flex;
                      align-items: center;
                    ">
                    <i class="bx bx-cart-alt" style="font-size: 22px; color: #ffffff"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <button type="button" class="btn" style="
      background-color: #0c8140;
      color: #fff;
      border-radius: 40px;
      padding: 10px 80px;
    ">
    Start Now
  </button>
</section> -->

<!--dots image-->
<!-- <div class="dots-container">
  <div class="dots">
    <img src="../../../assets/images/dots.png" />
  </div>
</div> -->

<!--About Section-->
<!-- <section class="about">
  <h3 class="title">
    We believe in the capacity of
    <span style="color: #0c8140">secure transactions</span> to impact the way
    people buy at ShopLife
  </h3>

  <div class="security-container">
    <div style="height: 500px; width: 400px">
      <img src="../../../assets/images/security.png" style="height: 100%; width: 100%" />
    </div>

    <div>
      <p>Security First</p>
      <p class="content">
        Your peace of mind is non-negotiable. We've built a fortress of security
        protocols to safeguard your transactions, ensuring that your hard-earned
        money is held securely until you receive what you paid for
      </p>
    </div>
  </div>

  <div class="transport-container">
    <div style="height: 450px; width: 400px">
      <img src="../../../assets/images/man-cart.png" style="height: 100%; width: 100%" />
    </div>

    <div>
      <p>Transparency & Fairness</p>
      <p class="content">
        We champion transparency. Our platform ensures that our customers are
        treated fairly. No more uncertainties; just straightforward, transparent
        transactions
      </p>
    </div>
  </div>

  <div class="security-container">
    <div style="height: 450px; width: 400px">
      <img src="../../../assets/images/process.png" style="height: 100%; width: 100%" />
    </div>

    <div>
      <p>Simplified Process</p>
      <p class="content">
        We understand that convenience is key. ShopLife Escrow simplifies the
        entire escrow process, making it easy for anyone to use, whether you're
        a seasoned online shopper or a first-time buyer
      </p>
    </div>
  </div>
</section> -->

<!--Products Photo Section-->
<!-- <section class="photo-section">
  <h3 class="title">
    At ShopLife, we are not just a <span style="color: #0c8140">service</span> ;
    we are a commitment for secure,
    <span style="color: #0c8140">trustworthy</span> , and seamless online
    shopping experience
  </h3>
  <div style="display: flex; flex-direction: column; gap: 20px">
    <div class="images-container">
      <div class="fruits-container">
        <img src="../../../assets/images/fruits.png" alt="fruits" style="width: 100%; height: 100%" />
      </div>
      <div class="small-img">
        <div class="img-container">
          <div class="pic">
            <img src="../../../assets/images/grains.png" alt="fruits" style="width: 100%; height: 100%" />
          </div>
          <div class="pic">
            <img src="../../../assets/images/potatoes.png" alt="fruits" style="width: 100%; height: 100%" />
          </div>
        </div>

        <div class="img-container">
          <div class="pic">
            <img src="../../../assets/images/nuts.png" alt="fruits" style="width: 100%; height: 100%" />
          </div>
          <div class="pic">
            <img src="../../../assets/images/vegeis.png" alt="fruits" style="width: 100%; height: 100%" />
          </div>
        </div>
      </div>
    </div>
    <div>
      <p style="font-weight: 500; color: #000; margin-bottom: 5px">
        Fresh Agricultural produce
      </p>
      <p style="font-weight: 400; color: #000; max-width: 450px">
        Farmers and buyers in the agricultural sector can leverage ShopLife
        Escrow to facilitate fair transactions.
      </p>
      <button style="
          border: 1px solid #0c8140;
          padding: 10px 20px;
          border-radius: 20px;
          background: transparent;
        ">
        Start Now
      </button>
    </div>
  </div>
</section> -->

<!--Logistics Section-->
<!-- <section class="logistic-section"></section> -->

<!--shoplife water-->
<!-- <section class="container-fluid water-product-section">
  <div class="row justify-content-center">
    <div class="col-xl-3 col-sm-12 d-flex justify-content-center align-items-center" *ngFor="let logistic of logistics">
      <div class="" style="width: 375px; height: 413px">
        <img src="{{ logistic.image }}" class="card-img-top" alt="..." />
        <div class="d-flex flex-column justify-content-center mt-4">
          <p class="card-text title" style="text-align: left">
            {{ logistic.title }}
          </p>
          <p class="card-text">{{ logistic.description }}</p>
        </div>
      </div>
    </div>
  </div>
</section> -->

<!--our process-->
<section class="container-fluid process-section">
  <div class="row justify-content-center">
    <p style="text-align: center">Our Process</p>
    <h3 style="text-align: center; color: #000; max-width: 600px">
      Shoplife ensures <span style="color: #0c8140">transactions</span> are
      facilitated, secured, <span style="color: #0c8140">transparent</span>, for
      user
      <span style="color: #0c8140">satisfaction</span>
    </h3>
  </div>
</section>

<!--dots image-->
<div class="dots-container">
  <div class="dots">
    <img src="../../../assets/images/pink-dot.png" />
  </div>
</div>

<div class="dots" style="transform: rotate(45deg); position: absolute; left: 0">
  <img src="../../../assets/images/pink-dot.png" />
</div>

<section class="container" style="display: flex; flex-direction: column; position: relative">
  <ul class="progressbar">
    <li class="active"></li>
    <li class="active"></li>
    <li class="active"></li>
    <li class="active"></li>
  </ul>

  <div class="container-fluid row">
    <div class="col">
      <p>Users Registration</p>
      <p style="font-size: 12px">
        Buyers register on the ShopLife Escrow platform, providing necessary
        details for account creation.
      </p>
    </div>
    <div class="col">
      <p>Transaction Initiation</p>
      <p style="font-size: 12px">
        The buyer initiates the transaction, providing details such as the
        transaction amount, item description, and any other relevant
        information.
      </p>
    </div>
    <div class="col">
      <p>Buyer's Payment</p>
      <p style="font-size: 12px">
        The buyer transfers the agreed-upon payment to the escrow account.
        ShopLife Escrow verifies the payment.
      </p>
    </div>
    <div class="col">
      <p>Transaction Completion</p>
      <p style="font-size: 12px">
        The transaction is marked as complete. Both parties have the option to
        leave feedback or reviews.
      </p>
    </div>
  </div>

  <div class="btn-container">
    <button type="button" class="btn" style="
        background-color: #0c8140;
        color: #fff;
        border-radius: 40px;
        padding: 10px 60px;
      ">
      Get Started for free
    </button>
  </div>
</section>

<!--FAQ-->
<section class="faq">
  <h3 class="title">
    Frequently Asked <span style="color: #0c8140">Questions</span> (FAQ) -
    ShopLife
  </h3>

  <div style="max-width: 800px; margin: 0 auto; margin-top: 20px">
    <div class="accordion accordion-flush" id="accordionFlushExample">
      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingOne">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
            How do I place an order?
          </button>
        </h2>
        <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne"
          data-bs-parent="#accordionFlushExample">
          <div class="accordion-body">
            Placeholder content for this accordion, which is intended to
            demonstrate the <code>.accordion-flush</code> class. This is the
            first item's accordion body.
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingTwo">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
            How do I track my order?
          </button>
        </h2>
        <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo"
          data-bs-parent="#accordionFlushExample">
          <div class="accordion-body">
            Placeholder content for this accordion, which is intended to
            demonstrate the <code>.accordion-flush</code> class. This is the
            second item's accordion body. Let's imagine this being filled with
            some actual content.
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingThree">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
            I missed my delivery. What happens now?
          </button>
        </h2>
        <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree"
          data-bs-parent="#accordionFlushExample">
          <div class="accordion-body">
            Placeholder content for this accordion, which is intended to
            demonstrate the <code>.accordion-flush</code> class. This is the
            third item's accordion body. Nothing more exciting happening here in
            terms of content, but just filling up the space to make it look, at
            least at first glance, a bit more representative of how this would
            look in a real-world application.
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingFour">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
            Can I change my delivery information (address/phone) after placing
            my order?
          </button>
        </h2>
        <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour"
          data-bs-parent="#accordionFlushExample">
          <div class="accordion-body">
            Placeholder content for this accordion, which is intended to
            demonstrate the <code>.accordion-flush</code> class. This is the
            third item's accordion body. Nothing more exciting happening here in
            terms of content, but just filling up the space to make it look, at
            least at first glance, a bit more representative of how this would
            look in a real-world application.
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingFive">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
            What is bank transfer and how does it work?
          </button>
        </h2>
        <div id="flush-collapseFive" class="accordion-collapse collapse" aria-labelledby="flush-headingFive"
          data-bs-parent="#accordionFlushExample">
          <div class="accordion-body">
            Placeholder content for this accordion, which is intended to
            demonstrate the <code>.accordion-flush</code> class. This is the
            third item's accordion body. Nothing more exciting happening here in
            terms of content, but just filling up the space to make it look, at
            least at first glance, a bit more representative of how this would
            look in a real-world application.
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingSix">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
            I want to reset my password
          </button>
        </h2>
        <div id="flush-collapseSix" class="accordion-collapse collapse" aria-labelledby="flush-headingSix"
          data-bs-parent="#accordionFlushExample">
          <div class="accordion-body">
            Placeholder content for this accordion, which is intended to
            demonstrate the <code>.accordion-flush</code> class. This is the
            third item's accordion body. Nothing more exciting happening here in
            terms of content, but just filling up the space to make it look, at
            least at first glance, a bit more representative of how this would
            look in a real-world application.
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!--contact us-->
<section class="container-fluid contact">
  <p style="text-align: center">Contact us</p>
  <div>
    <h3 class="title">
      We are here to <span style="color: #0c8140">assist</span> you. If you have
      any <span style="color: #0c8140">questions</span>, concerns, or need
      support
    </h3>
  </div>
  <div class="contact-container container-fluid">
    <div style="position: relative; margin-top: 60px">
      <div class="contact-detail-container">
        <div style="display: flex; flex-direction: column; gap: 20px">
          <div>
            <p style="
                color: #fff;
                font-size: 24px;
                margin-bottom: 5px;
                font-weight: 600;
              ">
              Contact Information
            </p>
            <p style="color: #fff; font-size: 14px">
              Say something to start a live chat!
            </p>
          </div>

          <div style="display: flex; flex-direction: column; gap: 10px">
            <div style="display: flex; gap: 10px">
              <div>
                <img src="../../../assets/images/bxs_phone-call.png" style="width: 17px; height: 17px" />
              </div>
              <p style="font-size: 12px">+1012 3456 789</p>
            </div>
            <div style="display: flex; gap: 10px">
              <div>
                <img src="../../../assets/images/ic_sharp-email.png" style="width: 17px; height: 17px" />
              </div>
              <p style="font-size: 12px">demo@gmail.com</p>
            </div>
            <div style="display: flex; gap: 10px">
              <div>
                <img src="../../../assets/images/carbon_location-filled.png" style="width: 17px; height: 17px" />
              </div>
              <p style="font-size: 12px; max-width: 50%; font-weight: 400">
                132 Dartmouth Street Boston, Massachusetts 02156 United States
              </p>
            </div>
          </div>
        </div>

        <div style="display: flex; align-items: center; gap: 20px">
          <div>
            <img src="../../../assets/images/twitter.png" style="width: 30px; height: 30px" />
          </div>
          <div>
            <img src="../../../assets/images/instagram.png" style="width: 30px; height: 30px" />
          </div>
          <div>
            <img src="../../../assets/images/discord.png" style="width: 30px; height: 30px" />
          </div>
        </div>
      </div>

      <div style="position: absolute; right: 0; bottom: 3px">
        <img src="../../../assets/images/elipse-two.png"
          style="height: auto; width: 150px; object-fit: contain; z-index: 1" />
      </div>
      <div style="position: absolute; right: 1px; bottom: 3px">
        <div style="background-color: transparent">
          <img src="../../../assets/images/elipse-one.png"
            style="height: auto; width: 100px; object-fit: contain; z-index: 0" />
        </div>
      </div>
    </div>
    <div>
      <form style="display: flex; flex-direction: column; gap: 40px">
        <div style="display: flex; gap: 20px">
          <div style="display: flex; flex-direction: column; width: 278px">
            <label style="color: #8d8d8d; font-size: 12px; font-weight: 400">First Name</label>
            <div style="border-bottom: 1px solid #8d8d8d">
              <input type="text" style="border: none; background-color: transparent" />
            </div>
          </div>

          <div style="display: flex; flex-direction: column; width: 278px">
            <label style="color: #000; font-size: 12px; font-weight: 400">Last Name</label>
            <div style="border-bottom: 1px solid #8d8d8d">
              <input type="text" style="border: none; background-color: transparent" />
            </div>
          </div>
        </div>

        <div style="display: flex; gap: 20px">
          <div style="display: flex; flex-direction: column; width: 278px">
            <label style="color: #8d8d8d; font-size: 12px; font-weight: 400">Email</label>
            <div style="border-bottom: 1px solid #8d8d8d">
              <input type="text" style="border: none; background-color: transparent" />
            </div>
          </div>

          <div style="display: flex; flex-direction: column; width: 278px">
            <label style="color: #000; font-size: 12px; font-weight: 400">Phone Number</label>
            <div style="border-bottom: 1px solid #8d8d8d">
              <input type="text" style="border: none; background-color: transparent" />
            </div>
          </div>
        </div>

        <div>
          <p style="color: #000">Select Subject?</p>
          <div style="display: flex; gap: 20px">
            <div style="display: flex; align-items: center; gap: 10px">
              <div>
                <input type="radio" />
              </div>
              <label style="font-size: 12px">General Inquiry</label>
            </div>
            <div style="display: flex; align-items: center; gap: 10px">
              <div>
                <input type="radio" />
              </div>
              <label style="font-size: 12px">General Inquiry</label>
            </div>
            <div style="display: flex; align-items: center; gap: 10px">
              <div>
                <input type="radio" />
              </div>
              <label style="font-size: 12px">General Inquiry</label>
            </div>
            <div style="display: flex; align-items: center; gap: 10px">
              <div>
                <input type="radio" />
              </div>
              <label style="font-size: 12px">General Inquiry</label>
            </div>
          </div>
        </div>

        <div style="display: flex; flex-direction: column">
          <label style="color: #8d8d8d; font-size: 12px">Message</label>
          <div style="border-bottom: 1px solid #8d8d8d">
            <textarea placeholder="Write your message.." style="border: none; background-color: transparent"></textarea>
          </div>
        </div>

        <div style="display: flex; justify-content: flex-end; position: relative">
          <button style="
              background-color: #eb810a;
              color: #fff;
              border: none;
              border-radius: 40px;
              padding: 10px 40px;
            ">
            Send a message
          </button>

          <div style="position: absolute; bottom: -100px; right: 200px">
            <img src="../../../assets/images/line-arrow.png" style="height: 100px" />
          </div>
        </div>
      </form>
    </div>
  </div>
  <div style="position: absolute; left: 0; top: 0">
    <img src="../../../assets/images/gray-dots.png" />
  </div>
</section>

<!--footer-->

<footer class="footer footer-container">
  <div class="container-fluid">
    <div style="border: 0.004rem solid #ffffff; margin-bottom: 35px"></div>
    <div class="row" style="display: grid; grid-template-columns: repeat(5, 1fr)">
      <div>
        <p class="item-title">Reach Us</p>
        <div>
          <p class="item-list">+1012 3456 789</p>
          <p class="item-list">demo@gmail.com</p>
          <p class="item-list">
            132 Dartmouth Street Boston, Massachusetts 02156 United States
          </p>
        </div>
      </div>
      <div>
        <p class="item-title">Company</p>
        <div>
          <p class="item-list">About</p>
          <p class="item-list">Contact</p>
          <p class="item-list">Blog</p>
        </div>
      </div>
      <div>
        <p class="item-title">Legal</p>
        <div>
          <p class="item-list">Privacy Policy</p>
          <p class="item-list">Terms & Service</p>
          <p class="item-list">Terms of Use</p>
          <p class="item-list">Refund Policy</p>
        </div>
      </div>
      <div>
        <p class="item-title">Quick Links</p>
        <div>
          <p class="item-list">TechLabz Keybox</p>
          <p class="item-list">Downloads</p>
          <p class="item-list">Forum</p>
        </div>
      </div>
      <div style="
          background-color: #fa8707;
          height: fit-content;
          border-radius: 10px;
          padding: 15px;
        ">
        <p class="item-title" style="padding-top: 5px">Join Our Newsletter</p>

        <div style="
            display: flex;
            align-items: center;
            height: 39px;
            margin-bottom: 10px;
            width: 100%;
            border-radius: 10px;
          ">
          <input type="text" placeholder="Your email address" style="height: 100%; font-size: 11px; width: 150px" />
          <button type="button" style="border: none; background: #0c8140; color: #fff; height: 100%">
            Subscribe
          </button>
        </div>

        <p style="color: #ffffff">
          * Will send you weekly updates for your better tool management.
        </p>
      </div>
    </div>
  </div>
</footer>