import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Inject,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { AuthenticationService } from "../../core/services/auth.service";
import { CookieService } from "ngx-cookie-service";
import { LanguageService } from "../../core/services/language.service";
import { TranslateService } from "@ngx-translate/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { first } from "rxjs/operators";
import { RegisterUserDto } from "src/app/pages/customer/customer.model";
import { BehaviorSubject, Observable } from "rxjs";
import { CartItem } from "./cart.model";
import { CartService } from "./cart.service";
import { NgbDropdownModule } from "@ng-bootstrap/ng-bootstrap";
import { HttpClient } from "@angular/common/http";
import { EnvService } from "src/app/core/services/env.service";
import { query } from "chartist";
import { EcommerceService } from "src/app/pages/ecommerce/ecommerce.service";

@Component({
  selector: "app-topbar",
  templateUrl: "./topbar.component.html",
  styleUrls: ["./topbar.component.scss"],
})

/**
 * Topbar component
 */
export class TopbarComponent implements OnInit {
  element;
  cookieValue;
  flagvalue;
  countryName;
  valueset;
  cart = [];

  isLoggedIn = false;
  showLogin = true;
  submitted = false;
  submitted2 = false;
  isLoading = false;
  isLoading2 = false;
  enabled: boolean = false;
  success = "";
  error = "";
  error2 = "";
  loginForm: UntypedFormGroup;
  registerForm: UntypedFormGroup;

  countryList = [{ value: "Nigeria", label: "Nigeria" }];
  stateList = [{ value: "Abuja", label: "Abuja" }];
  lgaList = [{ value: "Abuja East", label: "Abuja East" }];

  searchQuery: string = "";
  searchResult: any[] = [];
  outOfStockMessage: string = "";

  private userCartSubject: BehaviorSubject<CartItem[]>;
  public userCart: Observable<CartItem[]>;
  constructor(
    @Inject(DOCUMENT) private document: any,
    private router: Router,
    private authService: AuthenticationService,
    public languageService: LanguageService,
    public translate: TranslateService,
    public _cookiesService: CookieService,
    private modalService: NgbModal,
    private formBuilder: UntypedFormBuilder,
    private readonly cartService: CartService,
    private http: HttpClient,
    private envService: EnvService,
    private readonly ecommerceService: EcommerceService
  ) {
    this.userCartSubject = new BehaviorSubject<CartItem[]>([]);
    this.userCart = this.userCartSubject.asObservable();

    // this.cart = this.cartService.userCartValue;
  }

  /**
   * Returns user cart
   */
  public get userCartValue(): CartItem[] {
    return this.userCartSubject.value;
  }

  redirectToPage(route: string) {
    this.router.navigateByUrl(route);
  }

  listLang = [
    { text: "English", flag: "assets/images/flags/us.jpg", lang: "en" },
    { text: "Spanish", flag: "assets/images/flags/spain.jpg", lang: "es" },
    { text: "German", flag: "assets/images/flags/germany.jpg", lang: "de" },
    { text: "Italian", flag: "assets/images/flags/italy.jpg", lang: "it" },
    { text: "Russian", flag: "assets/images/flags/russia.jpg", lang: "ru" },
  ];

  openMobileMenu: boolean;
  username = "";
  customerId;
  isSearchVisible: boolean = false;

  obj = {
    email: null,
    password: null,
  };

  registrationForm = {
    firstname: null,
    lastname: null,
    email: null,
    phone: null,
    password: null,
    confirmPassword: null,
    country: null,
    state: null,
    lga: null,
    city: null,
    address: null,
  };

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();
  @ViewChild("loginModal") loginModalRef: TemplateRef<any>;

  showAuthModal(content: any) {
    this.router.navigate(["/account/login"]);
    // this.modalService.dismissAll();
    // setTimeout(() => {
    //   this.modalService.open(content, {
    //     centered: true,
    //     size: "md",
    //     animation: true,
    //     // backdrop: "static",
    //     keyboard: true,
    //   });
    // }, 200);
  }

  openLoginModal(content: TemplateRef<any>) {
    this.modalService
      .open(content, {
        centered: true,
        size: "md",
        animation: true,
        backdrop: "static",
        keyboard: false,
      })
      .result.then((result) => {
        console.log("Modal closed" + result);
      })
      .catch((res) => {});
  }

  hamdleShowRegisterModal(content: any) {
    this.modalService.dismissAll();
    setTimeout(() => {
      this.modalService.open(content, { centered: true, size: "lg" });
    }, 200);
  }

  toggleSearchBar(event: Event): void {
    this.isSearchVisible = !this.isSearchVisible;
  }

  ngOnInit() {
    this.username = JSON.parse(localStorage.getItem("currentUser"))?.fullName;
    this.customerId = JSON.parse(localStorage.getItem("currentUser"))?.id;
    this.enabled = JSON.parse(localStorage.getItem("currentUser"))?.enabled;

    // if (this.customerId) {
    //   this.cartService.getUserCartItems(this.customerId).subscribe(
    //     (response: any) => {
    //       // this.cart = response?.data;
    //       // this.cartService.cartState$.subscribe((cartItems) => {
    //       //   console.log("CARTITEMS!!! ", cartItems);
    //       //   this.cart = cartItems;
    //       // });
    //     },
    //     (error) => {
    //       console.log(
    //         "🚀 ~ TopbarComponent ~ this.cart=this.cartService.getUserCartItems ~ error:",
    //         error
    //       );
    //     }
    //   );
    // }

    this.cartService.cartState$.subscribe((cart) => {
      this.cart = cart;
    });

    this.loginForm = this.formBuilder.group({
      email: ["", [Validators.required]],
      password: ["", [Validators.required]],
    });

    const isLoggedIn = localStorage.getItem("isLoggedIn");
    console.log("is loggedIn!!", isLoggedIn);
    console.log("🚀 ~ TopbarComponent ~ ngOnInit ~ isLoggedIn:", isLoggedIn);
    this.isLoggedIn = JSON.parse(isLoggedIn) != null ? true : false;

    this.registerForm = this.formBuilder.group({
      firstname: ["", [Validators.required]],
      lastname: ["", [Validators.required]],
      email: ["", [Validators.required]],
      phone: ["", [Validators.required]],
      country: ["", [Validators.required]],
      state: ["", [Validators.required]],
      lga: ["", [Validators.required]],
      city: ["", [Validators.required]],
      address: ["", [Validators.required]],
      password: ["", [Validators.required]],
      confirmPassword: ["", [Validators.required]],
    });

    this.openMobileMenu = false;
    this.element = document.documentElement;

    this.cookieValue = this._cookiesService.get("lang");
    const val = this.listLang.filter((x) => x.lang === this.cookieValue);
    this.countryName = val.map((element) => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.valueset = "assets/images/flags/us.jpg";
      }
    } else {
      this.flagvalue = val.map((element) => element.flag);
    }
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  // convenience getter for easy access to form fields
  get getRegistrationForm() {
    return this.registerForm.controls;
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  ngAfterViewInit() {
    const drawerCloseButton = document.querySelector(
      '[data-dismiss="drawer"]'
    ) as HTMLButtonElement;
    if (drawerCloseButton) {
      drawerCloseButton.addEventListener("click", this.closeDrawer.bind(this));
    }

    const drawerOpenButton = document.querySelector(
      '[data-toggle="drawer"]'
    ) as HTMLButtonElement;
    if (drawerOpenButton) {
      drawerOpenButton.addEventListener("click", this.openDrawer.bind(this));
    }
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  // logout() {
  //   this.authService.logout();
  //   window.location.reload();
  // }

  logout() {
    this.authService.logout();

    if (this.router.url.includes("profile")) {
      this.authService.logout();
      this.router.navigate(["/"]);
    } else {
      this.authService.logout();
      window.location.reload();
    }
  }

  /**
   * Fullscreen method
   */

  openModal() {
    const modalDiv = document.getElementById("myModal");
    if (modalDiv !== null) {
      modalDiv.style.display = "block";
      modalDiv.style.marginTop = "200px";
      modalDiv.style.padding = "50px";
    }
  }

  closeModal() {
    const modalDiv = document.getElementById("myModal");
    if (modalDiv !== null) {
      modalDiv.style.display = "none";
    }
  }

  onSubmit() {
    this.submitted = true;
    const userRegisterDto = {
      username: this.obj.email,
      password: this.obj.password,
    };
    this.authService
      .login(userRegisterDto)
      .pipe(first())
      .subscribe(
        (data) => {
          this.success = "Login successful";
          this.error = "";
          setTimeout(() => {
            this.modalService.dismissAll();
          }, 300);
          window.location.reload();
          // this.router.navigate(["/dashboard"]);
        },
        (error) => {
          this.error = error ? error : "";
        }
      );
  }

  onRegister() {
    this.submitted2 = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    } else {
      console.log("registerForm__form ==>>", this.registerForm.value);

      const registerUserDto: RegisterUserDto = {
        username: String(
          `@${this.registerForm.get("firstname").value}-${
            this.registerForm.get("lastname").value
          }`
        ).toLowerCase(),
        password: this.registerForm.get("password").value,
        email: this.registerForm.get("email").value,
        fullName: `${this.registerForm.get("firstname").value} ${
          this.registerForm.get("lastname").value
        }`,
        phoneNumber: this.registerForm.get("phone").value,
        country: this.registerForm.get("country").value,
        state: this.registerForm.get("state").value,
        lga: this.registerForm.get("lga").value,
        cityOrTown: this.registerForm.get("city").value,
        address: this.registerForm.get("address").value,
      };
      console.log(
        "🚀 ~ TopbarComponent ~ onRegister ~ registerUserDto:",
        registerUserDto
      );

      // call registration service
      this.authService
        .register(registerUserDto)
        .pipe(first())
        .subscribe(
          (response) => {
            console.log(
              "🚀 ~ TopbarComponent ~ onRegister ~ response:",
              response
            );
            // this.router.navigate(["/dashboard"]);
            this.modalService.dismissAll();
            this.loginForm
              .get("email")
              .setValue(this.registerForm.get("email").value);
            this.loginForm
              .get("password")
              .setValue(this.registerForm.get("password").value);
            this.openLoginModal(this.loginModalRef);
            this.registerForm.reset();
          },
          (error) => {
            this.error2 = error ? error : "";
          }
        );
    }
  }

  toggleAuth() {
    this.showLogin = !this.showLogin;
  }

  onSearch(category: string) {
    if (category.trim()) {
      this.isLoading = true;
      this.ecommerceService.searchProduct(category).subscribe(
        (response: any) => {
          this.searchResult = response.data;
          if (this.searchResult.length === 0) {
            this.router.navigate(["app/ecommerce/search"], {
              queryParams: {
                query: category,
                outOfStockMessage: `${category} is out of stock`,
              },
            });
          }

          this.isLoading = false;
          // Redirect to search results page
          this.router.navigate(["/app/ecommerce/search"], {
            queryParams: { query: category },
          });
        },
        (error) => {
          console.error("Search error:", error);
          this.isLoading = false;
        }
      );
    }
  }

  navigateTo(route: string, tab: string) {
    this.router.navigate([route], { queryParams: { tab } });
  }

  closeDrawer() {
    const drawer = document.getElementById("orderModal");
    if (drawer) {
      drawer.classList.remove("show");
      drawer.style.width = "0";

      const orderButton = document.querySelector(
        '[data-toggle="modal"]'
      ) as HTMLButtonElement;
    }

    // Remove the backdrop
    const backdrop = document.querySelector(
      ".modal-backdrop.show"
    ) as HTMLElement;
    const backdropFade = document.querySelector(
      ".modal-backdrop.fade"
    ) as HTMLElement;
    if (backdrop) {
      backdrop.classList.remove("show");
      backdrop.style.opacity = "0";
    }

    if (backdropFade) {
      backdropFade.classList.remove("show");
      backdropFade.style.display = "none";
    }
    // Remove the background-color property from elements with the 'drawer' class
    const drawerElements = document.getElementsByClassName("drawer");
    for (let i = 0; i < drawerElements.length; i++) {
      const element = drawerElements[i] as HTMLElement;
      element.style.backgroundColor = "";
    }
  }

  // Function to open the drawer
  openDrawer() {
    const drawer = document.getElementById("orderModal");
    if (drawer) {
      drawer.classList.add("show");
      drawer.style.width = "600px";
    }

    // Show the backdrop with 'show' class
    const backdrop = document.querySelector(".modal-backdrop") as HTMLElement;
    if (backdrop) {
      backdrop.classList.add("show");
      backdrop.style.opacity = "0.5";
    }

    // Show the backdrop with 'fade' class
    const backdropFade = document.querySelector(
      ".modal-backdrop.fade"
    ) as HTMLElement;
    if (backdropFade) {
      backdrop.classList.add("show");
      backdropFade.style.display = "block";
    }
  }
}
