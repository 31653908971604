// tslint:disable-next-line: class-name
export class arrayModel {
  key: string;
  value: string;
}

// tslint:disable-next-line: class-name
export class productModel {
  id: number;
  category: string;
  name: string;
  ratings = 0;
  reviewCount = 0;
  discount: number;
  oriRate: number;
  disRate: number;
  feature: string[];
  description?: string;
  specification?: arrayModel[];
  images: string[];
  colorVariant: arrayModel[];
  price?: number;
  manufacture_name?: string;
  manufacture_brand?: string;
  formattedPrice?: string;
}

export class logisticModel {
  id: number;
  title: string;
  description: string;
  image: string;
}

// tslint:disable-next-line: class-name
export class filterObject {
  discountRates: number[];
  maxVal: number;
  minVal: number;
}

export const productList: productModel[] = [
  {
    id: 1,
    category: "T-shirts",
    name: "Half sleeves T-shirt",
    ratings: 0,
    reviewCount: 0,
    discount: 10,
    oriRate: 450,
    disRate: 405,
    feature: [
      "Fit Type: Regular Fit",
      "highest quality fabric",
      "Suitable for all weather condition",
      "Excellent Washing and Light Fastness",
    ],
    description: "A fitting T-shit for perfect outfit",
    specification: [
      {
        key: "size",
        value: "M",
      },
      {
        key: "color",
        value: "red",
      },
    ],
    images: [
      "assets/images/product/ofada.png",
      "assets/images/product/ofada.png",
      "assets/images/product/ofada.png",
    ],
    colorVariant: [
      {
        key: "red",
        value: "assets/images/product/img-1.png",
      },
      {
        key: "black",
        value: "assets/images/product/img-1.png",
      },
    ],
    price: 70000,
  },
  {
    id: 2,
    category: "T-shirts",
    name: "Black T-shirt",
    ratings: 0,
    reviewCount: 0,
    discount: 20,
    oriRate: 225,
    disRate: 175,
    feature: [
      "Fit Type: Regular Fit",
      "highest quality fabric",
      "Suitable for all weather condition",
      "Excellent Washing and Light Fastness",
    ],
    description: "A fitting T-shit for perfect outfit",
    specification: [
      {
        key: "size",
        value: "M",
      },
      {
        key: "color",
        value: "Light Blue",
      },
    ],
    images: [
      "assets/images/product/ofada-1.png",
      "assets/images/product/ofada-1.png",
      "assets/images/product/ofada-1.png",
    ],
    colorVariant: [
      {
        key: "red",
        value: "assets/images/product/img-2.png",
      },
      {
        key: "yellow",
        value: "assets/images/product/img-2a.png",
      },
      {
        key: "green",
        value: "assets/images/product/img-2b.png",
      },
    ],
    price: 70000,
  },
  {
    id: 3,
    category: "T-shirts",
    name: "Printed T-shirt",
    ratings: 0,
    reviewCount: 0,
    discount: 14,
    oriRate: 177,
    disRate: 152,
    feature: [
      "Fit Type: Regular Fit",
      "highest quality fabric",
      "Suitable for all weather condition",
      "Excellent Washing and Light Fastness",
    ],
    description: "A fitting T-shit for perfect outfit",
    specification: [
      {
        key: "size",
        value: "M",
      },
      {
        key: "color",
        value: "black",
      },
    ],
    images: [
      "assets/images/product/potatoes.png",
      "assets/images/product/potatoes.png",
      "assets/images/product/potatoes.png",
    ],
    colorVariant: [
      {
        key: "blue",
        value: "assets/images/product/img-3a.png",
      },
      {
        key: "yellow",
        value: "assets/images/product/img-3.png",
      },
    ],
    price: 70000,
  },
];

export const logisticList: logisticModel[] = [
  {
    id: 1,
    title: "Shoplife Water",
    description:
      "individuals or businesses renting warehouse space can use ShopLife Escrow to ensure that rental payments are made securely.",
    image: "../../../assets/images/water.png",
  },
  {
    id: 2,
    title: "Shoplife Water",
    description:
      "individuals or businesses renting warehouse space can use ShopLife Escrow to ensure that rental payments are made securely.",
    image: "../../../assets/images/water.png",
  },
  {
    id: 3,
    title: "Shoplife Water",
    description:
      "individuals or businesses renting warehouse space can use ShopLife Escrow to ensure that rental payments are made securely.",
    image: "../../../assets/images/water.png",
  },
];
