<footer class="footer">
  <div class="container-fluid">
    <div style="border: 0.004rem solid #ffffff; margin-bottom: 20px"></div>

    <div class="row footer-container">
      <div>
        <div>
          <p class="item-list" style="display: flex; flex-direction: column; gap: 10px;">
            <span style="font-weight: bold;">Nigeria Office</span>
            <span style="line-height: 30px;">
              Yakubu
              Gowon Centre Tower in Plot 493,
              Abogo Largema Street, CBD Abuja, Nigeria
            </span>
          </p>

          <p class="item-list" style="display: flex; flex-direction: column; gap: 10px;">
            <span style="font-weight: bold;">UK Office</span>
            <span style="line-height: 30px;">
              Shoplife Agro Limited, 106 Lord Street, Allenton, Derby DE249AU.
            </span>
          </p>
        </div>
      </div>
      <div>
        <p class="item-title">Reach Us</p>
        <div>
          <p class="item-list">(234) 703 355 5479</p>
          <p class="item-list">info@shoplife.ng</p>

        </div>
      </div>
      <div>
        <p class="item-title">Company</p>
        <div>
          <p class="item-list" style="cursor: pointer;" routerLink="/app/ecommerce/faq">FAQ</p>
        </div>
      </div>
      <div>
        <p class="item-title">Legal</p>
        <div>
          <p class="item-list" style="cursor: pointer;" routerLink="/app/ecommerce/privacy">Privacy Policy</p>
          <p class="item-list" style="cursor: pointer;" routerLink="/app/ecommerce/terms">Terms & Condition</p>
          <p class="item-list" style="cursor: pointer;" routerLink="/app/ecommerce/refund-policy">Refund & Return Policy
          </p>
        </div>
      </div>

      <div style="display: flex; flex-direction: column; gap: 10px;">
        <div style="height: fit-content; border-radius: 10px;">
          <p class="item-title grid-span" style="padding-top: 5px">Join Our Newsletter</p>
          <div
            style="display: flex; align-items: center; height: 39px; margin-bottom: 10px; width: 100%; border-radius: 10px;">
            <input type="text" placeholder="Your email address" style="height: 100%; font-size: 11px; width: 100%;" />
            <button type="button"
              style="border:none; background: #0C8140; color: #fff; height: 100%;">Subscribe</button>
          </div>
          <p style="color: #ffffff;">
            * Will send you weekly updates for your better<br> tool management.
          </p>
        </div>

        <div>
          <p class="item-title grid-span" style="padding-top: 5px; margin-bottom: unset; margin-bottom: 10px;">
            Certifications</p>
          <div style="display: flex;  flex-direction: column; gap: 40px;">
            <div
              style="color: #fff; font-size: 18px; font-weight: bold; margin-top: 5px; display: flex; align-items: center; gap: 10px;">
              <div style="width: 60px; height: 50px;">
                <img src="../../../assets/images/efcc.png"
                  style="width: 100%; object-fit: contain; font-family: Nunito Sans, sans-serif;" />
              </div>
              <span>SCUML Certified</span>
            </div>

            <div
              style="color: #fff; font-size: 18px; font-weight: bold; margin-top: 5px; display: flex; align-items: center; gap: 10px;">
              <div style="width: 100px; height: 50px;">
                <img src="../../../assets/images/nepc.jpeg"
                  style="width: 100%; object-fit: contain; font-family: Nunito Sans, sans-serif;" />
              </div>
              <span>NIGERIAN EXPORT PROMOTION COUNCIL</span>
            </div>
          </div>
        </div>


      </div>


    </div>





  </div>
</footer>