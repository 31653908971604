import { Injectable } from "@angular/core";

export enum Environment {
  Prod = "prod",
  Staging = "staging",
  Test = "test",
  Dev = "dev",
  Local = "local",
}

@Injectable({ providedIn: "root" })
export class EnvService {
  private _env: Environment;
  private _userService: string;
  private _warehousesService: string;
  private _financialService: string;
  private _locationService: string;

  get env(): Environment {
    return this._env;
  }

  get userService(): string {
    return this._userService;
  }

  get warehouseService(): string {
    return this._warehousesService;
  }

  get financialService(): string {
    return this._financialService;
  }

  get locationService(): string {
    return this._locationService;
  }

  constructor() {}

  init(): Promise<void> {
    return new Promise((resolve) => {
      this.setEnvVariables();
      resolve();
    });
  }

  private setEnvVariables(): void {
    const hostname = window && window.location && window.location.hostname;
    console.log("this is the hostname =>", hostname);
    if (/^.*localhost.*/.test(hostname)) {
      this._env = Environment.Local;
      console.log("this is the _env ::", this._env);

      this._userService = "https://appgateway.shoplife.ng/api/users-service";

      this._financialService = this._financialService =
        "https://appgateway.shoplife.ng/api/financial-service";

      this._locationService =
        "https://appgateway.shoplife.ng/api/location-service";

      this._warehousesService = this._warehousesService =
        "https://appgateway.shoplife.ng/api/warehouse-service";
    } else if (/^shoplife-web-app-mu.vercel.app/.test(hostname)) {
      this._env = Environment.Staging;
      console.log("this is the _env ::", this._env);

      this._userService = "https://appgateway.shoplife.ng/api/users-service";

      this._financialService = this._financialService =
        "https://appgateway.shoplife.ng/api/financial-service";

      this._locationService =
        "https://appgateway.shoplife.ng/api/location-service";

      this._warehousesService = this._warehousesService =
        "https://appgateway.shoplife.ng/api/warehouse-service";
    } else if (/^shoplife-web-app.vercel.app/.test(hostname)) {
      this._env = Environment.Staging;
      console.log("this is the _env ::", this._env);

      this._userService = "https://appgateway.shoplife.ng/api/users-service";

      this._financialService = this._financialService =
        "https://appgateway.shoplife.ng/api/financial-service";

      this._locationService =
        "https://appgateway.shoplife.ng/api/location-service";

      this._warehousesService = this._warehousesService =
        "https://appgateway.shoplife.ng/api/warehouse-service";
    } else if (/^shoplife.ng/.test(hostname)) {
      this._env = Environment.Prod;
      console.log("this is the _env ::", this._env);

      this._userService = "https://appgateway.shoplife.ng/api/users-service";

      this._financialService = this._financialService =
        "https://appgateway.shoplife.ng/api/financial-service";

      this._locationService =
        "https://appgateway.shoplife.ng/api/location-service";

      this._warehousesService = this._warehousesService =
        "https://appgateway.shoplife.ng/api/warehouse-service";
    } else if (/^www.shoplife.ng/.test(hostname)) {
      this._env = Environment.Prod;
      console.log("this is the _env ::", this._env);

      this._userService = "https://appgateway.shoplife.ng/api/users-service";

      this._financialService = this._financialService =
        "https://appgateway.shoplife.ng/api/financial-service";

      this._locationService =
        "https://appgateway.shoplife.ng/api/location-service";

      this._warehousesService = this._warehousesService =
        "https://appgateway.shoplife.ng/api/warehouse-service";
    } else {
      console.warn(`Cannot find environment for host name ${hostname}`);
    }
  }
}
