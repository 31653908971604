<!-- ========== Left Sidebar Start ========== -->

<ng-template #contentTemplate>
  <!-- Sidemenu -->
  <div id="sidebar-menu" style="display: flex; flex-direction: column; height: 90vh;">
    <ul class="metismenu list-unstyled" id="side-menu" #sideMenu style="flex-grow: 1;">
      <!-- <li class="menu-title">Agriculture 2</li> -->
      <ng-container *ngFor="let item of menuItems">
        <li>
          <!-- Parent menu item with or without subItems -->
          <a *ngIf="hasItems(item)" href="javascript:void(0);" class="is-parent" [ngClass]="{
              'has-arrow': !item?.badge,
              'has-dropdown': item?.badge
            }" routerLinkActive="active">
            <!-- <i class="bx bx-{{ item?.icon }}" style="color: #000" *ngIf="item?.icon"></i> -->
            <img src="../../../assets/images/icons/{{item?.icon}}.png" width="12%" height="12%"
              style="margin-right: 3%;" />
            <span style="color: #000"> {{ item?.name | translate }}</span>
          </a>

          <!-- Direct link to a route without subItems  -->
          <a routerLink="/app/ecommerce/product-category/{{
              formatLink(item?.name)
            }}" *ngIf="!hasItems(item)" class="side-nav-link-ref">
            <!-- <i class="bx bx-{{ item?.icon }}" *ngIf="item?.icon"></i> -->
            <img src="../../../assets/images/icons/{{item?.icon}}.png" width="12%" height="12%"
              style="margin-right: 3%;" />
            <span> {{ item?.name | translate }}</span>
            <span class="badge rounded-pill bg-{{ item?.badge?.variant }} float-end" *ngIf="item?.badge">
              {{ item?.badge?.text | translate }}
            </span>
          </a>

          <!-- Submenu items -->
          <ul *ngIf="hasItems(item)" class="sub-menu" aria-expanded="false">
            <li *ngFor="let subitem of item.subItems">
              <!-- Direct link to a route -->
              <a routerLink="/app/ecommerce/product-category/{{
                  formatLink(item?.name)
                }}" *ngIf="!hasItems(subitem)" class="side-nav-link-ref" [attr.data-parent]="subitem?.parentId"
                [ngClass]="{
                  'inactive-item': !isActive(subitem),
                  'active-item': isActive(subitem)
                }" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                style="font-family: Nunito Sans, sans-serif">
                <!-- {{ item | json }} -->
                <!-- <i class="bx bx-{{ item?.icon }}" style="color: #000" *ngIf="item?.icon"></i> -->
                <img src="../../../assets/images/icons/{{subitem?.icon}}.png" width="12%" height="12%"
                  style="margin-right: 3%;" />
                {{ subitem?.name }}
              </a>

              <!-- Submenu items with dropdown -->
              <a *ngIf="hasItems(subitem)" class="side-nav-link-a-ref has-arrow" href="javascript:void(0);"
                [attr.data-parent]="subitem?.parentId">
                <!-- <i class="bx bx-{{ item?.icon }}" style="color: #000" *ngIf="item?.icon"></i> -->
                <img src="../../../assets/images/icons/{{subitem?.icon}}.png" width="12%" height="12%"
                  style="margin-right: 3%;" />
                {{ subitem?.name }}
              </a>
              <ul *ngIf="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                <li *ngFor="let subSubitem of subitem.subItems" style="cursor: pointer; margin-left: 15%;">
                  <!-- Direct link to a route -->
                  <a (click)="navigateTo('/app/ecommerce/product-sub-category/' + formatLink(subSubitem?.name))"
                    [attr.data-parent]="subSubitem?.parentId" [ngClass]="{
                       'inactive-item': !isActive(subSubitem),
                       'active-item': isActive(subSubitem)
                     }" class="side-nav-link-ref">
                    {{ subSubitem?.name | translate }}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ng-container>
      <!-- <li>
        <a href="javascript:void(0);" class="is-parent" routerLinkActive="active">
          <i class="bx bx-bowl-hot" style="color: #000;"></i>
          <span style="color: #000;">Water</span>
        </a>
      </li> -->
    </ul>

    <div style="padding: 10px;">
      <!-- <button class="btn btn-danger w-100" (click)="logoutUser()">Logout</button> -->
      <button style="background-color: transparent; border: none;">Can&apos;t find your preferred product? Click the
        link below</button>

      <div style="display: flex; justify-content: center;">
        <button type="button" class="btn btn-success" data-toggle="modal" data-target="#orderModal">Order
          products</button>
      </div>

    </div>
  </div>
</ng-template>
<!-- Modal to request unavailable products -->
<div class="modal fade" id="orderModal" tabindex="-1" role="dialog" aria-labelledby="orderModalLabel"
  aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="orderModalLabel">Order Product</h5>
        <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form style="display: flex; flex-direction: column; gap: 20px;">
          <div class="form-group">
            <label for="fullName" style="font-family: Nunito Sans, sans-serif;">Full Name</label>
            <input type="text" class="form-control" id="fullName" placeholder="Enter full name"
              [(ngModel)]="user.fullName" name="fullName">
          </div>
          <div class="form-group">
            <label for="email" style="font-family: Nunito Sans, sans-serif;">Email</label>
            <input type="email" class="form-control" id="email" placeholder="Enter email" [(ngModel)]="user.email"
              name="email">
          </div>
          <div class="form-group">
            <label for="phoneNumber" style="font-family: Nunito Sans, sans-serif;">Phone Number</label>
            <input type="text" class="form-control" id="phoneNumber" placeholder="Enter phone number"
              [(ngModel)]="user.phoneNumber" name="phoneNumber">
          </div>
          <div class="form-group">
            <label for="productName" style="font-family: Nunito Sans, sans-serif;">Product Name</label>
            <input type="text" class="form-control" id="productName" placeholder="Enter product name"
              [(ngModel)]="user.productName" name="productName">
          </div>
          <div class="form-group">
            <label for="quantity" style="font-family: Nunito Sans, sans-serif;">Quantity</label>
            <input type="number" class="form-control" id="quantity" placeholder="Enter quantity"
              [(ngModel)]="user.quantity" name="quantity">
          </div>
          <div class="form-group">
            <label for="address" style="font-family: Nunito Sans, sans-serif;">Shipping Address</label>
            <input type="text" class="form-control" id="address" placeholder="Enter shipping address"
              [(ngModel)]="user.deliveryAddress" name="address">
          </div>
          <div class="form-group">
            <label for="description" style="font-family: Nunito Sans, sans-serif;">Additional Information</label>
            <textarea id="description" placeholder="Additional Information...." name="description" rows="4"
              class="form-control" [(ngModel)]="user.additionalInformation"></textarea>
          </div>

          <div style="display: flex; gap: 10px;">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-submit" (click)="submitManualOrder()">Submit Request</button>
          </div>
        </form>
      </div>

    </div>
  </div>
</div>


<div class="vertical-menu">
  <!-- Scrollable area for the sidebar -->
  <ngx-simplebar class="h-100" #componentRef>
    <!-- Content template based on condensed or expanded state -->
    <div *ngIf="!isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
    <div *ngIf="isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
  </ngx-simplebar>
</div>
<!-- Left Sidebar End -->