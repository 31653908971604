<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex justify-content-between align-items-center" style="position: relative;">
      <div class="navbar-brand-box">
        <a routerLink="/" class="logo logo-light">
          <div>
            <img src="../../../assets/images/shoplife-logo.png" class="logo" style="height: 90px;" />
          </div>
        </a>
      </div>

      <button type="button" style="color: #000000;" class="btn btn-sm px-3 font-size-16 header-item d-block d-md-none"
        id="vertical-menu-btn" (click)="toggleMobileMenu($event)">
        <i class="fa fa-fw fa-bars"></i>
      </button>



      <button type="button" style="color: #000000;" class="btn btn-sm px-3 font-size-16 header-item d-block d-md-none"
        id="vertical-menu-btn" (click)="toggleSearchBar($event)">
        <i class="fa fa-fw fa-search"></i>
      </button>

      <div *ngIf="isSearchVisible" class="app-search d-block d-sm-none"
        style="display: flex; flex-direction: column; align-items: center; justify-content: center; position: absolute; top: 60px; left: 50px;">
        <form (ngSubmit)="onSearch(searchQuery)">
          <div class="position-relative"
            style="display: flex; gap: 10px; border: 1px solid gray; border-radius: 20px; background-color: white; color: #000000;">
            <input type="text" class="form-control search-input" placeholder="Search product..."
              [(ngModel)]="searchQuery" name="searchQuery" style="background-color: white; color: #000000;" />
            <button type="submit" color="primary" class="btn-md btn-rounded"
              style="background: #0c8140; color: #fff; border: none; padding: 8px 40px;">
              Search
            </button>
          </div>
          <p *ngIf="!enabled && isLoggedIn"
            style="color: red; background-color: transparent; text-align: center; margin-top: 10px; padding: 5px;">
            You have not verified your account <a href="/account/verify-otp">Click here to verify</a>
          </p>
        </form>
      </div>



      <form class="app-search search-div d-none d-sm-block" (ngSubmit)="onSearch(searchQuery)"
        [ngStyle]="{ 'margin-top': !enabled && isLoggedIn ? '30px' : '' }"
        style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
        <div class="position-relative" style="
            display: flex;
            gap: 10px;
            border: 1px solid gray;
            border-radius: 20px;
            background-color: transparent;
          ">
          <input type="text" class="form-control search-input" placeholder="Search product..." [(ngModel)]="searchQuery"
            name="searchQuery" style="background-color: transparent; color: #000000;" />
          <Button *ngIf="!isLoading" type=" button" color="primary" class="btn-md btn-rounded" style="
              background: #0c8140;
              color: #fff;
              border: none;
              padding: 8px 40px;
            ">
            Search
          </Button>
          <Button *ngIf="isLoading" type=" button" color="primary" class="btn-md btn-rounded" style="
                        background: #0c8140;
                        color: #fff;
                        border: none;
                        padding: 8px 40px;
                      ">
            Searching...
          </Button>
        </div>
        <p *ngIf="!enabled && isLoggedIn"
          style="color: red; background-color: transparent; text-align: center; margin-top: 10px; padding: 5px;">You
          have not verified
          your account <a href="/account/verify-otp">Click
            here to verify</a></p>
      </form>
    </div>

    <div class="d-flex" style="margin-right: 3rem">
      <div *ngIf="isLoggedIn" class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" style="display: flex; flex-direction: row; align-items: center">
          <div class="dropdown d-inline-block" ngbDropdown>
            <button type="button" class="btn header-item" ngbDropdownToggle
              style="display: flex; flex-direction: row; align-items: center">
              <i class="bx bx-user" style="font-size: 16px; color: #000000"></i>
              <span class="d-none d-xl-inline-block ms-1" style="font-size: 14px; color: #000000">{{ username }}</span>
            </button>
            <div ngbDropdownMenu>
              <button class="dropdown-item" (click)="navigateTo('/app/ecommerce/profile', 'profile')">Profile</button>
              <button class="dropdown-item" (click)="navigateTo('/app/ecommerce/profile', 'orders')">My Orders</button>
              <!-- <button class="dropdown-item" (click)="logout()">Logout</button> -->
              <button class="dropdown-item" (click)="navigateTo('/app/ecommerce/profile', 'settings')">Settings</button>
              <!-- <button type="button" class="dropdown-item" ngbDropdownToggle id="page-header-user-dropdown"
                style="display: flex; flex-direction: row; align-items: center">
                <span class="d-none d-xl-inline-block ms-1" style="font-size: 14px; color: #000000">{{ username
                  }}</span>
              </button> -->
            </div>
          </div>
        </button>
      </div>


      <div class="dropdown d-inline-block">
        <button type="button" class="btn header-item noti-icon"
          style="display: flex; flex-direction: row; align-items: center" id="page-header-notifications-dropdown"
          (click)="redirectToPage('/app/ecommerce/cart')">
          <i class="bx bx-cart-alt" style="font-size: 16px; color: #000000"></i>
          <span class="badge bg-danger rounded-pill">{{ cart != null ? cart?.length : 0 }}</span>
          <span class="d-none d-xl-inline-block ms-1" style="font-size: 14px; color: #000000">Cart
          </span>
        </button>
      </div>

      <div *ngIf="!isLoggedIn" class="dropdown d-inline-block">
        <a type="button" class="btn header-item" style="display: flex; flex-direction: row; align-items: center"
          (click)="showAuthModal(loginModal)">
          <span class="d-none d-xl-inline-block ms-1 btn btn-rounded" style="
              font-size: 14px;
              color: #000000;
              padding: 5px 18px;
              border: 1px solid #000000;
            ">LOG IN</span>
        </a>
      </div>

      <div class="dropdown d-inline-block"></div>

      <div *ngIf="isLoggedIn" class="dropdown d-inline-block" ngbDropdown>
        <!-- <button type="button" class="btn header-item" ngbDropdownToggle id="page-header-user-dropdown"
          style="display: flex; flex-direction: row; align-items: center">
          <span class="d-none d-xl-inline-block ms-1" style="font-size: 14px; color: #000000">{{ username }}</span>
        </button> -->
        <button type="button" class="btn header-item" ngbDropdownToggle id="page-header-user-dropdown"
          style="display: flex; flex-direction: row; align-items: center; color: #000;"
          (click)="logout()">Logout</button>

      </div>
    </div>
  </div>

  <div class="modal" id="myModal">
    <div class="modal-dialog modal-md">
      <div class="modal-content" style="padding: 20px">
        <!-- Modal Header -->
        <div style="
            background-color: #0c814033;
            padding: 10px 15px;
            border-radius: 4px;
            display: flex;
            justify-content: space-between;
            align-items: center;
          ">
          <div>
            <h4 class="modal-title" style="
                color: #222222;
                font-size: 24px;
                line-height: 20px;
                margin-bottom: 10px;
              ">
              WELCOME BACK!
            </h4>
            <p style="font-size: 14px">
              Don&apos;t have an account yet?
              <a href="/" style="color: #0c8140">SIGN UP</a>
            </p>
          </div>
          <div>
            <img src="../../../assets/images/auth-img.svg" alt="login-image" />
          </div>
          <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeModal()"></button> -->
        </div>

        <!-- Modal body -->
        <div class="modal-body" style="display: flex; flex-direction: column; gap: 20px">
          <div style="position: relative">
            <span style="
                position: absolute;
                left: 10px;
                top: 50%;
                transform: translateY(-50%);
              ">
              <img src="../../../assets/images/email.svg" alt="Image" style="width: 30px; height: 30px" />
            </span>
            <input type="text" placeholder="Email Address" style="
                border: 1px solid #d9d9d980;
                width: 100%;
                height: 56px;
                padding-left: 50px;
                font-size: 14px;
                line-height: 24px;
              " />
          </div>
          <div style="position: relative">
            <span style="
                position: absolute;
                left: 10px;
                top: 50%;
                transform: translateY(-50%);
              ">
              <img src="../../../assets/images/password.svg" alt="Image" style="width: 30px; height: 30px" />
            </span>
            <input type="password" placeholder="Password" style="
                border: 1px solid #d9d9d980;
                width: 100%;
                height: 56px;
                padding-left: 50px;
                font-size: 14px;
                line-height: 24px;
              " />
          </div>
          <a href="/" style="
              color: #065000;
              text-decoration: underline;
              text-align: right;
            ">Forgot Password</a>

          <div>
            <div style="margin-bottom: 20px">
              <div style="display: flex; gap: 10px; align-items: center">
                <p style="
                    border-bottom: 1px solid #d9d9d980;
                    flex-grow: 1;
                    height: 0;
                  "></p>
                <p>or use</p>

                <p style="
                    border-bottom: 1px solid #d9d9d980;
                    flex-grow: 1;
                    height: 0;
                  "></p>
              </div>
            </div>

            <div style="
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 30px;
              ">
              <img src="../../../assets/images/google.svg" alt="Image" style="width: 30px; height: 30px" />
              <img src="../../../assets/images/facebook.svg" alt="Image" style="width: 30px; height: 30px" />
            </div>
          </div>

          <div style="display: flex; align-items: center; gap: 10px">
            <input type="checkbox" /> <span>Remember me</span>
          </div>
        </div>

        <div style="display: flex; justify-content: center">
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal" style="
              background: #0c8140;
              color: #fff;
              border-radius: 30px;
              padding: 16px 80px;
              border: none;
              width: 100%;
            " (click)="closeModal()">
            Login
          </button>
        </div>
      </div>
    </div>
  </div>

  <ng-template #loginModal role="document" let-modal>
    <div class="modal-body">
      <div class="card overflow-hidden">
        <div class="bg-soft bg-primary">
          <div class="row">
            <div class="col-7">
              <div class="text-primary p-4">
                <h5 class="text-primary">Welcome Back !</h5>
                <p>Sign in to continue to Shoplife.</p>
              </div>
            </div>
            <div class="col-5 align-self-end">
              <img src="assets/images/profile-img.png" alt="" class="img-fluid" />
            </div>
          </div>
        </div>
        <div class="card-body pt-0">
          <div>
            <a routerLink="/">
              <div class="avatar-md profile-user-wid mb-4">
                <span class="avatar-title rounded-circle bg-light">
                  <img src="assets/images/logo.svg" alt="" class="rounded-circle" height="34" />
                </span>
              </div>
            </a>
          </div>
          <div class="p-2">
            <form class="form-horizontal" (ngSubmit)="onSubmit()">
              <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{
                error
                }}</ngb-alert>
              <ngb-alert type="success" *ngIf="success" [dismissible]="false">{{
                success
                }}</ngb-alert>

              <div class="mb-3">
                <label for="email">Username/Email</label>
                <input type="text" class="form-control" [class.is-invalid]="email.invalid &amp;&amp; email.touched"
                  name="email" #email="ngModel" [(ngModel)]="obj.email" placeholder="Enter email" required />
                <div *ngIf="email.invalid &amp;&amp; email.touched">
                  <small *ngIf="email.errors?.required" class="text-danger">Email is Required</small>
                </div>
              </div>

              <div class="mb-3">
                <label for="password">Password</label>
                <div class="input-group auth-pass-inputgroup">
                  <input type="password" class="form-control"
                    [class.is-invalid]="password.invalid &amp;&amp; password.touched" name="password"
                    #password="ngModel" [(ngModel)]="obj.password" placeholder="Enter password" required />

                  <button class="btn btn-light ms-0" type="button" id="password-addon">
                    <i class="mdi mdi-eye-outline"></i>
                  </button>

                  <div *ngIf="password.invalid &amp;&amp; password.touched">
                    <small *ngIf="password.errors?.required" class="text-danger">Password is Required</small>
                  </div>
                </div>
              </div>

              <div style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                ">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="remember-check" />
                  <label class="form-check-label" for="remember-check">
                    Remember me
                  </label>
                </div>

                <div class="text-center">
                  <a routerLink="/account/reset-password" class="text-muted"><i class="mdi mdi-lock mr-1"></i> Forgot
                    your password?</a>
                </div>
              </div>

              <div class="mt-3 d-grid">
                <button *ngIf="!isLoading" class="btn btn-primary" type="submit">
                  Log In
                </button>
                <button *ngIf="isLoading" class="btn btn-primary" type="submit">
                  Logging In <span><i class="bx bx-loader bx-spin"></i></span>
                </button>
              </div>

              <!-- <div class="mt-4 text-center">
                  Don't have an account?<a
                    (click)="toggleAuth()"
                    style="margin-left: 0.5rem; cursor: pointer"
                    class="text-muted"
                    >Create One</a
                  >
                </div> -->
              <div class="mt-4 text-center">
                Don't have an account?<a (click)="hamdleShowRegisterModal(showRegisterModal)"
                  style="margin-left: 0.5rem; cursor: pointer" class="text-muted">Create One</a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #showRegisterModal role="document" let-modal>
    <div class="modal-body">
      <div class="card overflow-hidden">
        <div class="bg-soft bg-primary">
          <div class="row">
            <div class="col-7">
              <div class="text-primary p-4">
                <h5 class="text-primary">Welcome Back !</h5>
                <p>Sign in to continue to API Dashboard.</p>
              </div>
            </div>
            <div class="col-5 align-self-end">
              <img src="assets/images/profile-img.png" alt="" class="img-fluid" />
            </div>
          </div>
        </div>
        <div class="card-body pt-0">
          <div>
            <a routerLink="/">
              <div class="avatar-md profile-user-wid mb-4">
                <span class="avatar-title rounded-circle bg-light">
                  <img src="assets/images/logo.svg" alt="" class="rounded-circle" height="34" />
                </span>
              </div>
            </a>
          </div>
          <div class="p-2">
            <form class="form-horizontal" (ngSubmit)="onRegister()">
              <ngb-alert type="danger" *ngIf="error2" [dismissible]="false">{{
                error2
                }}</ngb-alert>

              <div class="row" style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                ">
                <div class="col-lg-6" style="border-right: 1px solid #ebe2e2">
                  <h5 style="margin-bottom: 1rem">Basic Information</h5>
                  <div class="mb-3">
                    <label for="firstname">First Name</label>
                    <input type="text" class="form-control" name="firstname" #firstname="ngModel"
                      [(ngModel)]="registrationForm.firstname"
                      [class.is-invalid]="firstname.invalid &amp;&amp; firstname.touched" placeholder="Enter first name"
                      required />
                    <div *ngIf="firstname.invalid &amp;&amp; firstname.touched">
                      <small *ngIf="firstname.errors?.required" class="text-danger">First name is Required</small>
                    </div>
                  </div>

                  <div class="mb-3">
                    <label for="email">Last Name</label>
                    <input type="text" class="form-control" name="lastname" #lastname="ngModel"
                      [(ngModel)]="registrationForm.lastname"
                      [class.is-invalid]="lastname.invalid &amp;&amp; lastname.touched" placeholder="Enter last name"
                      required />
                    <div *ngIf="lastname.invalid &amp;&amp; lastname.touched">
                      <small *ngIf="lastname.errors?.required" class="text-danger">Last name is Required</small>
                    </div>
                  </div>

                  <div class="mb-3">
                    <label for="email">Email</label>
                    <input type="email" class="form-control" name="email" #email="ngModel"
                      [(ngModel)]="registrationForm.email" [class.is-invalid]="email.invalid &amp;&amp; email.touched"
                      placeholder="Enter email" required />
                    <div *ngIf="email.invalid &amp;&amp; email.touched">
                      <small *ngIf="email.errors?.required" class="text-danger">Email is Required</small>
                    </div>
                  </div>

                  <div class="mb-3">
                    <label for="email">Phone</label>
                    <input type="text" class="form-control" name="phone" #phone="ngModel"
                      [(ngModel)]="registrationForm.phone" [class.is-invalid]="phone.invalid &amp;&amp; phone.touched"
                      placeholder="Enter phone number" required />
                    <div *ngIf="phone.invalid &amp;&amp; phone.touched">
                      <small *ngIf="phone.errors?.required" class="text-danger">Phone Number is Required</small>
                    </div>
                  </div>

                  <div class="mb-3">
                    <label for="password">Password</label>
                    <div class="input-group auth-pass-inputgroup">
                      <input type="password" class="form-control"
                        [class.is-invalid]="password.invalid &amp;&amp; password.touched" name="password"
                        #password="ngModel" [(ngModel)]="registrationForm.password" placeholder="Enter password"
                        required />

                      <button class="btn btn-light ms-0" type="button" id="password-addon">
                        <i class="mdi mdi-eye-outline"></i>
                      </button>

                      <div *ngIf="password.invalid &amp;&amp; password.touched">
                        <small *ngIf="password.errors?.required" class="text-danger">Password is Required</small>
                      </div>
                    </div>
                  </div>

                  <div class="mb-3">
                    <label for="confirmPassword">Confirm Password</label>
                    <div class="input-group auth-pass-inputgroup">
                      <input type="password" class="form-control" name="confirmPassword" #confirmPassword="ngModel"
                        [(ngModel)]="registrationForm.confirmPassword"
                        [class.is-invalid]="confirmPassword.invalid &amp;&amp; confirmPassword.touched"
                        placeholder="Confirm Password" required />

                      <button class="btn btn-light ms-0" type="button" id="password-addon">
                        <i class="mdi mdi-eye-outline"></i>
                      </button>

                      <div *ngIf="confirmPassword.invalid &amp;&amp; confirmPassword.touched">
                        <small *ngIf="confirmPassword.errors?.required" class="text-danger">Password Does not match is
                          Required</small>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <h5 style="margin-bottom: 1rem">Residential Information</h5>

                  <div class="mb-3">
                    <label for="email">Country</label>
                    <ng-select appearance="outline" [searchable]="false" [clearable]="false" name="country"
                      #country="ngModel" [(ngModel)]="registrationForm.country" [items]="countryList" bindLabel="label"
                      bindValue="value" placeholder="Selct Country"
                      [class.is-invalid]="country.invalid &amp;&amp; country.touched"></ng-select>
                    <div *ngIf="country.invalid &amp;&amp; country.touched">
                      <small *ngIf="country.errors?.required" class="text-danger">Country is Required</small>
                    </div>
                  </div>

                  <div class="mb-3">
                    <label for="email">State</label>
                    <ng-select appearance="outline" [searchable]="false" [clearable]="false" name="state"
                      #state="ngModel" [(ngModel)]="registrationForm.state" [items]="stateList" bindLabel="label"
                      bindValue="value" placeholder="Selct state"
                      [class.is-invalid]="state.invalid &amp;&amp; state.touched"></ng-select>
                    <div *ngIf="state.invalid &amp;&amp; state.touched">
                      <small *ngIf="state.errors?.required" class="text-danger">State is Required</small>
                    </div>
                  </div>

                  <div class="mb-3">
                    <label for="email">LGA</label>
                    <ng-select appearance="outline" [searchable]="false" [clearable]="false" name="lga" #lga="ngModel"
                      [(ngModel)]="registrationForm.lga" [items]="lgaList" bindLabel="label" bindValue="value"
                      placeholder="Selct lga" [class.is-invalid]="lga.invalid &amp;&amp; lga.touched"></ng-select>
                    <div *ngIf="lga.invalid &amp;&amp; lga.touched">
                      <small *ngIf="lga.errors?.required" class="text-danger">LGA is Required</small>
                    </div>
                  </div>

                  <div class="mb-3">
                    <label for="email">City</label>
                    <input type="text" class="form-control" name="city" #city="ngModel"
                      [(ngModel)]="registrationForm.city" [class.is-invalid]="city.invalid &amp;&amp; city.touched"
                      placeholder="Enter city" required />
                    <div *ngIf="city.invalid &amp;&amp; city.touched">
                      <small *ngIf="city.errors?.required" class="text-danger">City is Required</small>
                    </div>
                  </div>

                  <div class="mb-3">
                    <label for="email">Address</label>
                    <input type="text" class="form-control" name="address" #address="ngModel"
                      [(ngModel)]="registrationForm.address"
                      [class.is-invalid]="address.invalid &amp;&amp; address.touched" placeholder="Enter address"
                      required />
                    <div *ngIf="address.invalid &amp;&amp; address.touched">
                      <small *ngIf="address.errors?.required" class="text-danger">Address is Required</small>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-3 d-grid">
                <button *ngIf="!isLoading2" class="btn btn-primary" type="submit">
                  Register
                </button>
                <button *ngIf="isLoading2" class="btn btn-primary" type="submit">
                  Registering <span><i class="bx bx-loader bx-spin"></i></span>
                </button>
              </div>

              <div class="mt-4 text-center">
                Have an account?<a (click)="showAuthModal(loginModal)" style="margin-left: 0.5rem; cursor: pointer"
                  class="text-muted">Login</a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</header>