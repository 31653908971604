import { Component, OnInit } from "@angular/core";
import {
  logisticList,
  logisticModel,
  productList,
  productModel,
} from "./product.model";
import { RouterModule } from "@angular/router";

@Component({
  selector: "app-landing",
  templateUrl: "./landing.component.html",
  styleUrls: ["./landing.component.scss"],
})
export class LandingComponent implements OnInit {
  public products: productModel[] = [];
  public productTemp: productModel[] = [];
  public logistics: logisticModel[] = [];
  public logisticsTem: logisticModel[] = [];

  constructor() {}

  ngOnInit(): void {
    this.products = Object.assign([], productList);
    this.logistics = Object.assign([], logisticList);
  }

  formatCurrency(value: number): string {
    return "₦" + value.toFixed(2);
  }
}
