import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

// import { getFirebaseBackend } from '../../authUtils';

import { User } from "../models/auth.models";
import { Router } from "@angular/router";
import { RegisterUserDto } from "src/app/pages/customer/customer.model";
import { EnvService } from "./env.service";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  user: User;
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient, private envService: EnvService, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  /**
   * Returns the current user
   */
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   * Performs the auth
   * @param formData
   */
  login(credential) {
    console.log("logging in...");
    return (
      this.http
        // .post<any>(`${environment.apiUrl}/users-service/users/login`, credential)
        .post<any>(`${this.envService.userService}/users/login`, credential)
        .pipe(
          map((user) => {
            // login successful if there's a jwt token in the response
            console.log("user__service__response ::", user);
            if (user && user?.data?.token) {
              // store user details and jwt token in local storage to keep user logged in between page refreshes
              localStorage.setItem("isLoggedIn", "true");

              const userData = user?.data?.userDetails;
              console.log("userData !!", userData);

              userData.token = user.data.token;
              localStorage.setItem("currentUser", JSON.stringify(userData));
              localStorage.setItem("token", JSON.stringify(user?.data?.token));
              localStorage.removeItem("orderDetails");
              this.currentUserSubject.next(user?.data?.userDetails);
            }
            return user;
          })
        )
    );
  }

  /**
   * Performs the register
   * @param email email
   * @param password password
   */
  register(registerUserDto: RegisterUserDto) {
    console.log("creating user...");
    return this.http
      .post<any>(`${this.envService.userService}/users`, registerUserDto)
      .pipe(
        map((response) => {
          console.log("🚀 ~ AuthenticationService ~ map ~ response:", response);
          const userData = response?.data;
          localStorage.setItem("registeredUser", JSON.stringify(userData));
          return response;
        })
      );
  }

  /**
   * Reset password
   * @param email email
   */

  resetPassword(email: string): Observable<any> {
    console.log("🚀 ~ AuthenticationService ~ resetPassword ~ email:", email);
    const url = `${this.envService.userService}/users/request-password-change`;
    let params = new HttpParams().set("email", email);
    return this.http.post<any>(url, null, { params });
  }

  changePassword(obj: any) {
    console.log("🚀 ~ AuthenticationService ~ resetPassword ~ email:", obj);
    return this.http
      .post(`${this.envService.userService}/users/change/password`, obj)
      .pipe(
        map((response) => {
          console.log("Change Passwword", response);
          return response;
        })
      );
  }

  /**
   * Logout the user
   */
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    localStorage.removeItem("token");
    localStorage.removeItem("isLoggedIn");
    this.currentUserSubject.next(null);
    // window.location.reload();
    // this.router.navigate(["/account/login"]);
    window.location.replace('/');
  }
}
