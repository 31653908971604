import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { EnvService } from "src/app/core/services/env.service";
import { TransactionDto, UpdateOrderDto } from "./ecommerce.model";
import { Observable, throwError } from "rxjs";

@Injectable({ providedIn: "root" })
export class EcommerceService {
  url = "assets/data/data.json";
  constructor(private http: HttpClient, private envService: EnvService) {}

  private handleError(error: any): Observable<never> {
    console.error("An error occurred", error);
    return throwError(error);
  }

  uploadFile(data: any) {
    return this.http.post(`${this.envService.userService}/file/`, data).pipe(
      map((res: any) => {
        console.log("create user ::", res);
        return res.data;
      })
    );
  }

  // payment receipts
  postPaymentReceipt(createPaymentReceiptDto: any) {
    return this.http
      .post(
        `${this.envService.financialService}/order/upload-receipt`,
        createPaymentReceiptDto
      )
      .pipe(
        map((res: any) => {
          console.log("create user ::", res);
          return res;
        })
      );
  }

  // products
  getAllProducts() {
    return this.http
      .get(
        `${this.envService.warehouseService}/get/all/processed/products/with/pagination`
      )
      .pipe(
        map((res: any) => {
          console.log("create user ::", res);
          return res;
        })
      );
  }

  //check if the product has been bought
  hasBoughtProduct(productSourceId: number, customerId: number) {
    return this.http
      .get(
        `${this.envService.financialService}/order/get/product/status/${productSourceId}/${customerId}`
      )
      .pipe(
        map((res: any) => {
          console.log("has brought ::", res);
          return res;
        })
      );
  }

  //search endpoint
  searchProduct(searchQuery: string) {
    return this.http
      .get(
        `${
          this.envService.warehouseService
        }/search?keyword=${searchQuery}&page=${0}`
      )
      .pipe(
        map((res: any) => {
          console.log("searc res ::", res);
          return res;
        })
      );
  }

  //get product by product ID
  getProductById(productId: number) {
    return this.http
      .get(
        `${this.envService.warehouseService}/get/processed/product/${productId}`
      )
      .pipe(
        map((res: any) => {
          console.log("create user ::", res);
          return res;
        })
      );
  }

  // customer orders
  createOrder(data: any) {
    return this.http
      .post(`${this.envService.financialService}/order/customer-order`, data)
      .pipe(
        map((res: any) => {
          console.log("create user order ::", res);
          return res;
        })
      );
  }

  //order for unavailable product
  createManualOrder(data: any) {
    return this.http
      .post(
        `${this.envService.warehouseService}/manual-orders/create
`,
        data
      )
      .pipe(
        map((res: any) => {
          console.log("create manual order ::", res);
          return res;
        })
      );
  }

  // https://appgateway.shoplife.ng/api/financial-service/order/customer-id?page=0&customerId=36

  getCustomerOrders(
    customerId: number,
    page: number = 0,
    pageSize: number = 10
  ) {
    return this.http
      .get(
        `${this.envService.financialService}/order/customer-id?page=${page}&customerId=${customerId}`
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  getCustomerOrdersStats(customerId) {
    return this.http
      .get(
        `${this.envService.financialService}/order/count/status/${customerId}`
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  updateOrderInformation(updateOrderDto: UpdateOrderDto) {
    return this.http
      .put(
        `${this.envService.financialService}/order/update-order`,
        updateOrderDto
      )
      .pipe(
        map((res: any) => {
          console.log("create user ::", res);
          return res;
        })
      );
  }

  // billing information
  createBillingAddress(data: any) {
    return this.http
      .post(`${this.envService.warehouseService}/contact/create`, data)
      .pipe(
        map((res: any) => {
          console.log("create user ::", res);
          return res;
        })
      );
  }

  getBillingAddressesByCustomerId(customerId: number) {
    return this.http
      .get(`${this.envService.warehouseService}/contact/get/all/${customerId}`)
      .pipe(
        map((res: any) => {
          console.log("create user ::", res);
          return res;
        })
      );
  }

  // transaction routes
  createOrderTransaction(transactionDto: TransactionDto) {
    return this.http
      .post(
        `${this.envService.financialService}/api/flutterwave/save`,
        transactionDto
      )
      .pipe(
        map((res: any) => {
          console.log("create user ::", res);
          return res;
        })
      );
  }

  //create review
  createReview(productId: number, data: any) {
    return this.http
      .post(
        `${this.envService.warehouseService}/review/create/${productId}`,
        data
      )
      .pipe(
        map((res: any) => {
          console.log("created review", res);
          return res;
        })
      );
  }

  resendOtp(email: string) {
    return this.http
      .post(`${this.envService.userService}/users/resend-otp`, null, {
        params: {
          email: email,
        },
      })
      .pipe(
        map((res: any) => {
          console.log("resend otp :", res);
          return res;
        })
      );
  }

  //delivery routes
  getDeliveryRoute() {
    return this.http
      .get(`${this.envService.financialService}/logistic-companies/routes`)
      .pipe(
        map((res: any) => {
          console.log("delivery response ::", res);
          return res;
        })
      );
  }

  //fetch terms and conditions
  getTermsAndCondition() {
    return this.http
      .get(`${this.envService.warehouseService}/get/terms/condition`)
      .pipe(
        map((res: any) => {
          // console.log("terms & conditions ::", res.data[res.data.length - 1]);
          return res.data[res.data.length - 1];
        })
      );
  }

  //fetch privacy policy
  getPrivacyPolicy() {
    return this.http
      .get(`${this.envService.warehouseService}/get/data/privacy`)
      .pipe(
        map((res: any) => {
          return res.data[res.data.length - 1];
        })
      );
  }

  //get running orders
  getRunningOrders(id: number) {
    return this.http
      .get(
        `${this.envService.financialService}/order/financial/customer-id/${id}?page=0`
      )
      .pipe(
        map((res: any) => {
          return res.data;
        })
      );
  }

  //get return policy
  getReturnPolicy() {
    return this.http
      .get(`${this.envService.warehouseService}/get/return/policy`)
      .pipe(
        map((res: any) => {
          return res.data[res.data.length - 1];
        })
      );
  }

  //fetch frequently asked question
  getFaq() {
    return this.http
      .get(`${this.envService.warehouseService}/faq/get/questions`)
      .pipe(
        map((res: any) => {
          console.log("terms & conditions ::", res);
          return res;
        })
      );
  }

  //all products category
  getProductsCategory() {
    return this.http
      .get(`${this.envService.warehouseService}/get/all/categories`)
      .pipe(
        map((res: any) => {
          console.log("products category :", res);
          return res;
        })
      );
  }

  getProductsSubCategory(category_name: string) {
    return this.http
      .get(
        `${this.envService.warehouseService}/get/all/product/sub/categories/by/category/name/${category_name}`
      )
      .pipe(
        map((res: any) => {
          console.log("products category :", res);
          return res;
        })
      );
  }

  getProductsBySubCategory(category_name: string) {
    return this.http
      .get(
        `${this.envService.warehouseService}/get/all/product/type/by/sub/category/name/${category_name}`
      )
      .pipe(
        map((res: any) => {
          console.log("products category :", res);
          return res;
        })
      );
  }

  searchProductByName(search_params: string) {
    return (
      this.http
        // .get(`${this.envService.warehouseService}/search/${search_params}`)
        .get(
          `${this.envService.warehouseService}/search/by/category/subCategory/productName/with/pagination?searchTerm=${search_params}`
        )
        .pipe(
          map((res: any) => {
            console.log("products category :", res);
            return res;
          })
        )
    );
  }

  //get all continents
  getAllContinents() {
    return this.http
      .get(`${this.envService.locationService}/get/all/continents`)
      .pipe(
        map((res: any) => {
          console.log("continents :", res);
          return res;
        })
      );
  }

  //get all countries
  getAllCountries(continentName: string) {
    return this.http
      .get(
        `${this.envService.locationService}/get/all/countries/by/${continentName}`
      )
      .pipe(
        map((res: any) => {
          console.log("continents :", res);
          return res;
        })
      );
  }

  //get states
  getStates(state: string) {
    return this.http
      .get(
        `${this.envService.locationService}/get/all/stateOrProvince/by/${state}`
      )
      .pipe(
        map((res: any) => {
          console.log("res ::", res);
          return res;
        })
      );
  }

  getProvinces(state: string) {
    return this.http
      .get(
        `${this.envService.locationService}/get/all/localGovernmentArea/by/${state}`
      )
      .pipe(
        map((res: any) => {
          console.log("create user ::", res);
          return res;
        })
      );
  }

  //get all carousel slides
  getAllCarouselSlides() {
    return this.http
      .get(`${this.envService.warehouseService}/get/all/slider`)
      .pipe(
        map((res: any) => {
          console.log("carousel slide", res);
          return res;
        })
      );
  }

  //get all reviews
  getAllReviews() {
    return this.http
      .get(`${this.envService.warehouseService}/review/get/all`)
      .pipe(
        map((res: any) => {
          console.log("reveiews :", res);
          return res;
        })
      );
  }

  //get review by ID
  getReview(id: number) {
    return this.http
      .get(`${this.envService.warehouseService}/review/${id}`)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  // https://appgateway.shoplife.ng/api/warehouse-service/review/get/review/rating/2

  //get review ratings
  getReviewRatings(id: number) {
    return this.http
      .get(`${this.envService.warehouseService}/review/get/review/rating/${id}`)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  //validate otp
  validateOtp() {
    return this.http.get(`${this.envService.userService}`);
  }

  //delete address
  deleteAddress(contactId: number) {
    return this.http.delete(
      `${this.envService.warehouseService}/contact/delete/${contactId}`
    );
  }

  //deactivate user account
  deactivateUser(userId: number) {
    return this.http.put(
      `${this.envService.userService}/users/deactivate/account/${userId}`,
      null
    );
  }

  //update profile
  updateProfile(customerId: number, data) {
    return this.http
      .put(`${this.envService.userService}/users/${customerId}`, data)
      .pipe(catchError(this.handleError));
  }

  //get order by order Id
  getOrderById(orderId: number) {
    return this.http
      .get(
        `${this.envService.financialService}/order/get/customer-order/${orderId}`
      )
      .pipe(
        map((res: any) => {
          console.log("res ::", res);
          return res;
        })
      );
  }

  //order cancellation
  orderCancellation(data: any) {
    return this.http
      .post(`${this.envService.financialService}/order/cancel-order`, data)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  //payment recceipts
  getPaymentReceipts(customerId: number, page: number) {
    return this.http
      .get(
        `${this.envService.financialService}/order/get/transaction/details/by/id?userId=${customerId}&page=${page}`
      )
      .pipe(
        map((res: any) => {
          return res.data;
        })
      );
  }
}
