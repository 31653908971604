import { Component, OnInit } from "@angular/core";
import { EcommerceService } from "./pages/ecommerce/ecommerce.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  constructor(private readonly ecommerceService: EcommerceService) {
    this.getProductsCategory();
  }

  ngOnInit() {
    // document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
  }

  getProductsCategory() {
    this.ecommerceService.getProductsCategory().subscribe(
      (response: any) => {
        console.log("sidebar items :", response.data);
        // this.menuItems = response.data;
        localStorage.setItem("menuItems", JSON.stringify(response.data));
      },
      (error) => {
        console.log(
          "🚀 ~ HomeComponent ~ this.ecommerceService.getAllProducts ~ error:",
          error
        );
      }
    );
  }
}
