import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { EnvService } from "src/app/core/services/env.service";
import { CartItem } from "./cart.model";
import { HttpClient } from "@angular/common/http";

@Injectable({ providedIn: "root" })
export class CartService {
  private userCartSubject: BehaviorSubject<CartItem[]>;
  public userCart: Observable<CartItem[]>;

  private cartStateSubject = new BehaviorSubject<any | null>(null);
  public cartState$ = this.cartStateSubject.asObservable();

  constructor(
    private http: HttpClient,
    private readonly _envService: EnvService
  ) {
    // this.userCartSubject = new BehaviorSubject<CartItem[]>(
    this.userCartSubject = new BehaviorSubject<any[1]>(
      []
      // JSON.parse(localStorage.getItem("cart")) == null
      //   ? []
      //   : JSON.parse(localStorage.getItem("cart"))
    );
    this.userCart = this.userCartSubject.asObservable();
  }

  getCartState() {
    return this.cartStateSubject.getValue();
  }

  setCartState(newState: any) {
    this.cartStateSubject.next(newState);
  }

  updateCartState(newItem: any) {
    const currentState = this.cartStateSubject.getValue();
    this.cartStateSubject.next([...currentState, newItem]);
  }

  /**
   * Returns user cart
   */
  public get userCartValue(): CartItem[] {
    return this.userCartSubject.value;
  }

  /**
   * Get cart items
   */
  getUserCartItems(customerId: number) {
    return this.http
      .get(`${this._envService.warehouseService}/cart/get/cart/${customerId}`)
      .pipe(
        map((res: any) => {
          console.log("user cart ::", res);
          // this.userCartSubject.next(res.data);
          return res;
        })
      );
  }

  addItemToCart(cartItemDto) {
    return this.http
      .post(`${this._envService.warehouseService}/cart/add`, cartItemDto)
      .pipe(
        map((res: any) => {
          console.log("create user ::", res);
          return res;
        })
      );
  }

  updateCartItem(cartItemDto) {
    return this.http
      .put(`${this._envService.warehouseService}/cart/update`, cartItemDto)
      .pipe(
        map((res: any) => {
          console.log("create user ::", res);
          return res;
        })
      );
  }

  removeItemFromCart(cartId: number) {
    return this.http
      .delete(`${this._envService.warehouseService}/cart/delete/${cartId}`)
      .pipe(
        map((res: any) => {
          console.log("create user ::", res);
          return res;
        })
      );
  }

  clearCustomerCart(customerId: number) {
    return this.http
      .delete(
        `${this._envService.warehouseService}/cart/delete/all/${customerId}`
      )
      .pipe(
        map((res: any) => {
          console.log("create user ::", res);
          return res;
        })
      );
  }
}
